@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import "./colors.scss";
@import "./mediaQuery.scss";

body,
h1,
h2,
h3,
h4,
h5,
h5,
p,
span {
    font-family: "Lato", sans-serif !important;
}
.MuiTypography-root {
    font-family: "Lato", sans-serif !important;
}
.PageHeading {
    margin-bottom: 32px;
    margin-left: 0px;
    margin-top: 20px;
    @include sm-down {
        margin-top: 20px;
        margin-bottom: 0;
    }
    h1 {
        line-height: 1;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        color: $Black;
        margin-top: 0;
        margin-bottom: 0;
        @include sm-down {
            font-size: 22px;
        }
    }
    p {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
    }
    &.Diversity {
        margin-bottom: 10px;
        margin-top: 0; 
        @include sm-down {margin-bottom: 0;}
        h1 {
            @include sm-down {
                font-size: 20px;
            }
        }
    }
}

.CardOne {
    background-color: transparent !important;
    box-shadow: none !important;
}

.CardOneContent {
    padding: 24px !important;
    border-radius: 8px;
    background-color: $White;
    @include lg-down {
        padding: 15px !important;
    }
    span {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        color: $Gray;
        line-height: 0.8;
        display: block;
        margin-bottom: 10px;
    }
}

.Srt {
    min-height: 300px;
    padding-bottom: 32px !important;
    padding-top: 0;
}

.ChartInfo {
    font-size: 18px;
    height: 170px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    min-width: 70%;
    @include md-down {
        height: auto;
        min-width: 60%;
    }
    @include sm-down {
        justify-content: flex-start;
        height: auto;
        min-width: 100%;
    }
   &>div {
       align-self: center;
       width: 100%;
       &>div {
           display: flex;
           align-items: center;
           width: 100%;
           font-weight: 700;
           font-size: 15px;
           line-height: 1.3;

           @include md-down {
               font-size: 14px;
           }

           @include sm-down {
               font-size: 13px;
           }

           &:not(:last-child) {
               margin-bottom: 10px;

               @include sm-down {
                   margin-bottom: 14px;
               }
           }
           label {
               color: $Black;
               padding-right: 15px;

               @include sm-down {
                   padding-right: 10px
               }
           }
           span {
               &.Color {
                   flex: 0 0 16px;
                   max-width: 16px;
                   height: 16px;
                   display: inline-block;
                   border-radius: 50%;
                   margin-right: 8px;

                   @include sm-down {
                       flex: 0 0 14px;
                       max-width: 14px;
                       height: 14px;
                   }
               }
               &.Percent {
                   margin-left: auto;
                   font-weight: 700;
                   color: $Black;
                   line-height: 0.8;

                   @include md-down {
                       line-height: normal;
                   }
               }
           }
       }
   }
}
.DounutChartRightGrid {
    display: flex;
    justify-content: center;
    padding-left: 24px;
    @include md-down {
       padding-left: 0;
    }
}