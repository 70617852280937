$DataProtected1: #70d9b8;
$DataProtected2: #ef8c25;
$DataProtected3: #fa6c51;
$DataProtected4: #ebbf44;
$DataProtected5: #6cbf84;
$SafePorter: #03989e;
$DarkGrey: #5e5e5e;
$Gray: #8a8a8a;
$BlueGray: #ccccd1;
$Mint1: #e4f1ed;
$Mint2: #d8e7e2;
$Mint3: #c8f0e5;
$LightGray: #f0f0f0;
$BlueLightGray: #eef0f4;
$LightLightGray: #ededed;
$Black:#2e2e2e;
$White: #ffffff;
