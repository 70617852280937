@import "./colors.scss";
@import "./mediaQuery.scss";

.BgGreen {
    background-color:$Mint1 !important;
}
.ChartInfo {
    * {
        scrollbar-width: none;
        scrollbar-color: $Mint1 #ffffff;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #03989e;
        border-radius: 5px;
        border: 0px solid #ffffff;
    }
}

body .dashboardExport{
    position: absolute;
    top: 20px;
    right: 60px;
    z-index: 10000;
    background: #03989e;

}
